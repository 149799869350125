import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { NoPremiumNotificationCenterService } from './no-premium/no-premium-notification-center.service';
import { PremiumService } from './premium.service';

@Injectable()
export class PremiumGuardService {

  constructor(private premiumService: PremiumService, private notificationCenter: NoPremiumNotificationCenterService) {
  }

  canActivate(): Observable<boolean> {
    return this.premiumService.hasPremiumFeatures.pipe(first(),
      tap<boolean>(isPremium => {
        if (!isPremium) {
          this.notificationCenter.handlePremiumError('No premium account');
        }
      }));
  }

  canMatch(): Observable<boolean> {
    return this.canActivate();
  }

}
